/* 
Styles for displaying markdown
*/
.markdown>* {
    all: revert;
}
.markdown a {
    color: rgb(173, 70, 165);
    border-bottom: 2px solid rgb(173, 70, 165);
    transition: 0.4s;
}
.markdown a:hover {
    color: rgb(173, 70, 165);
}
/* 
Styles for displaying active scroll spy links
*/
.active {
    transition: 0.3s;
    border-bottom: 2px solid rgb(173, 70, 165);
}
